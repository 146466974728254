import React, { useEffect, useState } from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Scripts from "components/shared/Scripts";
import logo from "images/logo-white.svg";
import NewsletterInput from "./NewsletterInput";
// import mail from "images/footer_icons/email.svg";
import youtube from "images/footer_icons/yt.svg";
import facebook from "images/footer_icons/fb.svg";
import twitter from "images/footer_icons/tw.svg";
import x_logo from "images/footer_icons/x_logo_test.png";
import linkedin from "images/footer_icons/li.svg";
// import tencent from "images/footer_icons/tencentCloud.svg";
// import searchads from "images/footer_icons/searchAds.svg";

// PARTNERS
import tencent from "images/footer_icons/tencent_cloud.png";
import searchads from "images/footer_icons/splitmetrics.png";
import azure from "images/footer_icons/azure.png";
import aws_select from "images/footer_icons/select_tier_aws_partner.png";
import shangai_data_exchange from "images/footer_icons/shangai_data_exchange.png";

// MEMBERSHIPS
import br_chamber from "images/footer_icons/br_chamber_cropped.png";
import cbbc from "images/footer_icons/cbbc.png";
import euccc from "images/footer_icons/euccc_white.png";
import economist_intelligence from "images/footer_icons/economist_intelligence.svg";

import bccc from "images/footer_icons/bccc.png";

import { Link } from "gatsby";
import { removeUnderLineFromImgs } from "src/utils/functions";
import TopMessage from "components/TopMessage";
import ModalBanner from "components/ModalBanner";
import ModalBannerSmallScreen from "components/ModalBannerSmallScreen";

const siteSections = [
  {
    groupLabel: "Services",
    sections: [
      { label: "Legal Compliance", path: "/services/compliance/" },
      { label: "Cloud Services", path: "/services/hosting/" },
      { label: "Localization", path: "/services/localization/" },
      { label: "Distribution", path: "/services/distribution/" },
      { label: "User Acquisition", path: "/services/acquisition/" },
      { label: "Monetization", path: "/services/monetization/" },
      { label: "WeChat Services", path: "" },
      { label: "Go-To-Market", path: "" },
      { label: "E-Commerce", path: "" },
      { label: "Customer Support", path: "" },
    ],
  },
  {
    groupLabel: "Resources",
    sections: [
      { label: "Test Your Website", path: "/test-your-site-in-china/" },
      ,
      { label: "How-To Guides", path: "" },
      { label: "Blogs", path: "/blog/" },
      ,
      { label: "Government Documents", path: "/government-policies-china/" },
      ,
      { label: "Infographics", path: "/infographics/" },
      ,
      { label: "Game License Database", path: "/games/game-licenses/" },
      ,
    ],
  },
  {
    groupLabel: "Market Intelligence",
    sections: [
      { label: "App Store Index", path: "/market/app-stores/" },
      { label: "VR Store Index", path: "/market/vr-stores/" },
      { label: "App Index", path: "/market/apps/" },
      { label: "Cloud Provider Index", path: "/market/cloud-provider/" },
      { label: "Mobile Device Index", path: "/market/device-brands/" },
      { label: "Game Store Index", path: "/market/game-stores/" },
      { label: "Mobile Game Index", path: "/market/games/" },
    ],
  },
  {
    groupLabel: "About Us",
    sections: [
      { label: "Our Company", path: "/our-company/" },
      { label: "Success Stories", path: "/success/" },
      {
        label: "Careers",
        path: "https://c5vd01gz8x.jobs.feishu.cn/AppInChina",
      },
      { label: "Media", path: "/media/" },
    ],
  },
];

const renderLinksGroup = (group, groupIdx) => {
  return (
    <div
      key={groupIdx}
      className={classes.linksGroup}
      style={{
        display: "inline-block",
        paddingRight: "32px",
      }}
    >
      <h4>{group.groupLabel}</h4>
      <ul className={classes.whithoutBullets}>
        {group["sections"].map((section, sectionIdx) => {
          return section.path != "" ? (
            <li key={sectionIdx}>
              <Link to={section.path}>{section.label}</Link>
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

const iconsAndBadges = () => {
  return (
    <>
      {/* Partners and memberships */}
      <div className={classes.buttons}>
        {/* Official Partners */}
        <>
          <div
            style={{
              paddingRight: "8px",
              paddingBottom: "8px",
              display: "block",
              width: "100%",
            }}
          >
            Official Partners:
          </div>{" "}
          <br></br>
          <div
            className={classes.partnersGroup}
          >
            <div
              className={classes.partners}
            >
              <a
                target="_blank"
                href="https://aws.amazon.com/"
                className={classes.largeBadges}
                rel="noreferrer"
                style={{
                  paddingRight: "24px !important",
                }}
              >
                <img
                  src={aws_select}
                  alt="AWS Select Tier Partner"
                  loading="lazy"
                  height={75}
                  // className={classes.awsSelectPartner}
                />
              </a>
            </div>

            <div
                className={classes.partners + " " + classes.partners_with_wrap}
            >
              <a
                target="_blank"
                href="https://azure.microsoft.com/"
                className={classes.largeBadges}
                rel="noreferrer"
                style={{
                  paddingRight: "18px !important",
                  marginBottom: "4px !important",
                }}
              >
                <img src={azure} alt="Microsoft Azure" loading="lazy" height={30} />
              </a>

              <a
                target="_blank"
                href="https://intl.cloud.tencent.com/"
                className={classes.largeBadges}
                rel="noreferrer"
              >
                <img
                  src={tencent}
                  alt="Tencent"
                  loading="lazy"
                  height={27}
                  // width={96}
                />
              </a>
              
            </div>

            <div
              className={classes.partners}
            >
              <a
                target="_blank"
                href="https://splitmetrics.com//"
                className={classes.largeBadges}
                rel="noreferrer"
                style={{
                  paddingRight: "20px !important",
                  marginBottom: "1px !important",
                }}
              >
                <img
                  src={searchads}
                  alt="Splitmetrics"
                  loading="lazy"
                  height={28}
                />
              </a>
              
            </div>

            <div
              className={classes.partners}
            >
              <div
                href="#"
                className={classes.largeBadges}
                rel="noreferrer"
                style={{
                  paddingRight: "0px !important",
                  marginBottom: "4px !important",
                }}
              >
                <img src={shangai_data_exchange} alt="Shangai Data Eschange" loading="lazy" height={56} />
              </div>
            </div>
            
          </div>
        </>

        {/* Memberships */}
        <>
          <div
            style={{
              paddingRight: "8px",
              paddingBottom: "8px",
              paddingTop: "36px",
              display: "block",
              width: "100%",
            }}
          >
            Memberships:
          </div>{" "}
          <br></br>
          <div
            className={classes.memberships}
          >
            <a
              target="_blank"
              href="https://corporatenetwork.com/"
              className={classes.largeBadges}
              rel="noreferrer"
              style={{
                marginLeft: "-0px !important",
              }}
            >
              <img
                src={economist_intelligence}
                alt="Economist Intelligence Corporate Network"
                loading="lazy"
                height={55}
                // className={classes.chinaBritainBusinessCouncil}
              />
            </a>

            <a
              target="_blank"
              href="https://europeanchamber.com.cn"
              className={classes.largeBadges}
              rel="noreferrer"
              style={{
                marginLeft: "2px !important",
              }}
            >
              <img
                src={euccc}
                alt="European Union Chamber of Commerce in China"
                loading="lazy"
                height={62}
                // className={classes.chinaBritainBusinessCouncil}
              />
            </a>

            <a
              target="_blank"
              href="https://www.britishchamber.cn"
              className={classes.largeBadges}
              rel="noreferrer"
              style={{
                marginRight: "4px !important",
                marginLeft: "-6px !important",
                marginBottom: "8px !important",
              }}
            >
              <img
                src={br_chamber}
                alt="British Chamber"
                loading="lazy"
                height={46}
                // className={classes.britishChamber}
              />
            </a>

            <a
              target="_blank"
              href="https://www.cbbc.org/"
              className={classes.largeBadges}
              rel="noreferrer"
            >
              <img
                src={cbbc}
                alt="China Britain Business Council"
                loading="lazy"
                height={46}
                // className={classes.chinaBritainBusinessCouncil}
              />
            </a>
          </div>
        </>
      </div>

      {/* Social Media Logos */}
      <div style={{ marginTop: "4px" }} className={classes.buttons}>
        <div style={{ display: "flex" }} className={classes.smallButtons}>
          <a
            target="_blank"
            href="http://facebook.com/appinchina"
            rel="noreferrer"
          >
            <img
              src={facebook}
              alt="Facebook"
              loading="lazy"
              height={"34px !important"}
              width={"34px !important"}
            />
          </a>
          <a
            target="_blank"
            href="http://twitter.com/appinchina"
            rel="noreferrer"
          >
            <div
              style={{
                width: "34px",
                height: "34px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                border: "6px solid #ebebeb",
              }}
            >
              <img
                src={x_logo}
                alt="Twitter Test"
                loading="lazy"
                height={"30px !important"}
                width={"30px !important"}
              />
            </div>
          </a>
          <a
            target="_blank"
            href="http://linkedin.com/company/3742839/"
            rel="noreferrer"
          >
            <img
              src={linkedin}
              alt="Linkedin"
              loading="lazy"
              height={34}
              width={34}
            />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCLke7TMixnnOLMjjpI_Z2vw"
            rel="noreferrer"
          >
            <img
              src={youtube}
              alt="YouTube"
              loading="lazy"
              height={34}
              width={34}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default ({ titleLevel = "" }) => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      removeUnderLineFromImgs();
    }, 100);

    // window.innerWidth is not accesible on the global scope
    let intFrameWidth = window.innerWidth;
    setWindowWidth(intFrameWidth);
  }, []);

  // const reportWindowSize = () => {
  //   setWindowWidth(window.innerWidth);
  // };
  // window.onresize = reportWindowSize;

  let newsletterTitleText = "Fearlessly Enter The World’s Largest App Market";

  let titleRender = "";
  if (titleLevel == "") {
    titleRender = <h5>{newsletterTitleText}</h5>;
  } else if (titleLevel == "h4") {
    titleRender = <h4>{newsletterTitleText}</h4>;
  }

  return (
    <footer>
      <div className={classes.container}>
        <Layout>
          <div className={classes.left}>
            <div className={classes.siteLinksContainer}>
              {siteSections.map((group, groupIdx) =>
                renderLinksGroup(group, groupIdx)
              )}
            </div>

            {/* <img alt="logo" src={logo} /> */}

            {iconsAndBadges()}

            <p className={classes.privacyPolicy}>
              <Link to="/privacy-policy/">Privacy Policy</Link> | 
              <a
                href="/supplier-code-of-conduct.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Supplier Code of Conduct
              </a>
            </p>

            <div className={classes.addresAndCopy}>
              <p style={{ margin: 0 }}>
                East Wing, F3, China World Tower B, No.1 Jianguomenwai Avenue,
                Chaoyang, Beijing, China
              </p>
              <p>北京市朝阳区建国门外大街一号国贸大厦B座三层东翼</p>

              <p>© AppInChina Limited {new Date().getFullYear()}</p>
            </div>
          </div>

          {/* <div style={{display:"auto"}} className={classes.right}>
            <div className={classes.formContainer}>
              <NewsletterInput />
            </div>
          </div> */}
        </Layout>
      </div>
      {/* {
        windowWidth!=0
          ? windowWidth >=800
            ? <ModalBanner />
            : <ModalBannerSmallScreen />
          : null
      } */}
      <Scripts />
    </footer>
  );
};
